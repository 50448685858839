export const jobTasks = [
	'dispatching',
	'queued',
	'quality',
	'render:setup',
	'render:dorender',
	'render:postrender',
	'render:cleanup',
	'finished',
]
