import React from 'react'
import { Box, chakra, Text } from '@chakra-ui/react'

const MediaPackageLink = ({ mediaPackageLink }) => {
	return (
		<Box>
			<Text fontWeight="bold">Package Download Link</Text>
			{mediaPackageLink ? (
				<chakra.a href={mediaPackageLink} title="DOWNLOAD PACKAGE" color="blue.500">
					{/*{mediaPackageLink.split('?')[0]}*/}
					Click here to download media package
				</chakra.a>
			) : (
				<Text color="grey.500">No package download link yet.</Text>
			)}
		</Box>
	)
}

export default MediaPackageLink
