import React, { useEffect, useRef, useState } from 'react'
import { postPublishedMedia } from '../../../api/admin/media'
import { useDispatch } from 'react-redux'

import { chakra, Text, Button, Input } from '@chakra-ui/react'

const MediaForm = ({ data }) => {
	const dispatch = useDispatch()
	const urlRef = useRef(null)

	const [disablePublishBtn, setDisabledPublishButton] = useState(false)

	const handleSubmit = (e) => {
		const isDisabled = disablePublishBtn
		e.preventDefault()
		setDisabledPublishButton(true)
		const val = urlRef.current.value

		let postData = {
			eventId: data.id,
			uuid: data.uuid,
			url: val, // test with these animoto urls 'https://d2m23yiuv18ohn.cloudfront.net/Video/q6NDuhKeI61S4DvURJyp4A/360p.mp4' //'https://d2m23yiuv18ohn.cloudfront.net/Video/XewH3o7RlfiNY8easrbznw/1080p.mp4' //'https://d2m23yiuv18ohn.cloudfront.net/Video/XewH3o7RlfiNY8easrbznw/360p.mp4' https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4
		}

		if (!isDisabled) {
			dispatch(postPublishedMedia(postData))
		}
	}

	useEffect(() => {
		if (data.package) {
			setDisabledPublishButton(false)
		}
	}, [])

	return (
		<form onSubmit={handleSubmit}>
			<Text>
				Media URL File: <chakra.small color="red">(This will overwrite current VidDay)</chakra.small>{' '}
			</Text>
			<Input name="url" type="text" ref={urlRef} mb="1rem" />

			<Button size="xs" type="submit" minW="100px" disabled={disablePublishBtn}>
				Save
			</Button>
		</form>
	)
}

export default MediaForm
