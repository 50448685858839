import React, { useEffect } from 'react'
import io from 'socket.io-client'
import EventActionsBox from '../../../../../admin/components/V2/EventActionsBox'
import { Flex } from '@chakra-ui/react'
import { useEventContext } from '../../../../contexts/EventProvider'
import { useMedia } from '../../../../hooks/useMedia'
import usePublishedMedia from '../../../../hooks/usePublishedMedia'
import useRenderJobs from '../../../../hooks/useRenderJobs'
import { useEventRecipients } from '../../../../hooks/useRecipients'

/** REVIEW THIS COMPONENT AFTER UPGRADING ADMIN EVENT ACTIONS BOX SINCE WE ARE LOADING LOTS OF PROPS JUST TO PASS THEM DOWN */
const socket = io({
	transports: ['websocket'],
	addTrailingSlash: false,
})

const baseStyles = {
	'overflow': 'scroll',
	'height': 'calc(100vh - 130px)', // minus admin and nav bars
	'background': 'white',
	'justify': 'center',
	'minH': '4rem',
	'mt': '0',
	'userSelect': 'all !important',
	'& h1, & h2, & h3, & h4, & h5, & h6, & p': {
		userSelect: 'text !important',
		display: 'inherit',
	},
}

const SiteToolbox = () => {
	/** Retrieve Current Event */
	const { event } = useEventContext()
	const recipients = useEventRecipients()
	const media = useMedia()
	const publishedMedia = usePublishedMedia()
	const renderJobs = useRenderJobs()

	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.overflow = null
		}
	}, [])

	return (
		<Flex sx={baseStyles}>
			{event && (
				<EventActionsBox
					socket={socket}
					showBeta={true}
					renderJobs={renderJobs}
					media={media}
					data={event}
					recipients={recipients}
					publishedMedia={publishedMedia}
				/>
			)}
		</Flex>
	)
}

SiteToolbox.defaultProps = {}

SiteToolbox.propTypes = {}

export default SiteToolbox
