import React, { useEffect, useState } from 'react'
import { Box, Divider, Text, chakra } from '@chakra-ui/react'
import FinalViddayLink from './FinalViddayLink'
import MediaPackageLink from './MediaPackageLink'
import MediaForm from './MediaForm'
import Progress from '../../../v2/ui/molecules/Progress'
import { useSocketContext } from '../../../v2/contexts/SocketProvider'

const MediaPackageForm = ({ data }) => {
	const [mediaPackageProgress, setMediaPackageProgress] = useState(null)
	const [progressPercentage, setProgressPercentage] = useState(null)
	const [message, setMessage] = useState(null)
	const [mediaPackageLink, setMediaPackageLink] = useState(null)
	const [mediaErrors, setMediaErrors] = useState([])
	const [disablePublishBtn, setDisablePublishBtn] = useState(false)

	const {
		staff: { socket },
	} = useSocketContext()

	useEffect(() => {
		if (data.package) {
			setMediaPackageLink(data.signedPackageUrl)
			setDisablePublishBtn(false)
		}

		socket.on('mediaPackageProgress', (socketData) => {
			if (socketData.eventUuid == data.uuid) {
				setProgressPercentage(socketData.percentage)
				setMessage(socketData.message)

				if (socketData.link) {
					setMediaPackageLink(socketData.link)
				}
				if (socketData.media) {
					setMediaErrors([...mediaErrors, socketData.media])
				}
			}
		})

		return () => {
			socket.off('mediaPackageProgress')
		}
	}, [])

	const listOfErrorFiles = mediaErrors.map((media) => {
		return (
			<li key={media.mediaUuid}>
				Type:<strong>{media.mimeType}</strong>
				<br />
				<img width="100px" height="100px" src={media.signedThumbnailUrl} /> <br /> SortOrder:{' '}
				<strong>{media.sortOrder}</strong> File: <strong>{media.key}</strong>
				<br /> MediaUUID: <strong>{media.mediaUuid}</strong>
				<br />
				<br />
			</li>
		)
	})

	return (
		<Box>
			<Text fontWeight="bold" fontSize="1.5rem">
				Media Package
			</Text>

			{listOfErrorFiles.length > 1 && (
				<>
					<Divider my="1rem" />
					<chakra.ul>{listOfErrorFiles}</chakra.ul>
				</>
			)}

			{message && (
				<Box>
					<Text>{message}</Text>
					{progressPercentage != null && <Progress value={progressPercentage} size={50} />}
				</Box>
			)}

			<Divider my="1rem" />
			<FinalViddayLink data={data} />
			<Divider my="1rem" />
			<MediaPackageLink mediaPackageLink={mediaPackageLink} />
			<Divider my="1rem" />
			<Text fontWeight="bold">Media URL</Text>
			<MediaForm data={data} />
		</Box>
	)
}

export default MediaPackageForm
