import React, { useRef } from 'react'
import { updateEvent } from '../../../api/admin/events'
import { useDispatch } from 'react-redux'
import { Button, Textarea } from '@chakra-ui/react'

const NotesForm = ({ data }) => {
	const dispatch = useDispatch()
	const textareaRef = useRef(null)

	const handleSubmit = (e) => {
		e.preventDefault()
		const val = textareaRef.current.value
		let postData = {
			id: data.key || data.id,
			notes: val.toString(), // TODO : Escape this for safety
		}
		dispatch(updateEvent(postData))
	}

	return (
		<form onSubmit={handleSubmit}>
			<Textarea ref={textareaRef} placeholder="Add notes here." defaultValue={data.notes} mb="1rem" />
			<Button size="xs" type="submit" minW="100px">
				Save
			</Button>
		</form>
	)
}

export default NotesForm
