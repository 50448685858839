import React from 'react'
import { Text } from '@chakra-ui/react'
import EventForm from './EventForm'

const EventTab = ({ data, publishedMedia, videoLength }) => {
	const purchase = data.paid ? 'Paid' : null
	const totalVideoLength = data.length || videoLength

	return (
		<>
			{purchase && (
				<Text color="link">
					{purchase} ✓ | ⌛{totalVideoLength}
				</Text>
			)}

			<EventForm data={data} publishedMedia={publishedMedia} />
		</>
	)
}

export default EventTab
