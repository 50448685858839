import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { impersonateUser, unmask } from '../../../../../api/admin/users'
import { Box, Flex, Button, HStack, Stack, Text, Heading, Switch } from '@chakra-ui/react'
import { useEventContext } from '../../../../contexts/EventProvider'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { isMobile } from 'react-device-detect'
import SiteToolbox from '../src/SiteToolbox'
import usePublishedMedia from '../../../../hooks/usePublishedMedia'

const SiteStatus = ({ showToolbox, setToolboxVisibility, toolboxStyles }) => {
	const { event } = useEventContext()
	const publishedMedia = usePublishedMedia()
	const { role, email, isImpersonated } = useAuthContext()

	/** Use location hook */
	const location = useLocation()
	/** Use Redux Dispatch */
	const dispatch = useDispatch()

	const handleImpersonateUser = (userId) => {
		// if in browser, use current URL; if SSR, send back undefined to use the default
		let redirect = location ? location.pathname : undefined
		if (userId) dispatch(impersonateUser(userId, redirect))
	}

	const handleStopImpersonating = () => {
		// if in browser, use current URL; if SSR, send back undefined to use the default
		let redirect = location ? location.pathname : undefined
		dispatch(unmask(redirect))
	}

	// Close Toolbox on unmount or no Event
	useEffect(() => {
		if (showToolbox && (!event?.id || event?.id == undefined)) {
			setToolboxVisibility()
		}
	}, [event?.id])

	const baseStyles = {
		background: '#FF1C63',
		width: 'full',
		position: 'relative',
		height: '48px',
		boxShadow: 'md',
		zIndex: '1',
		pl: '1rem',
		jusitfy: 'space-between',
		align: 'center',
		color: 'white',
	}

	const status = event?.isRestoring ? ' - THAWING' : event?.inGlacierStorage ? ' - FROZEN' : ''

	return (
		<Box w="full" sx={toolboxStyles}>
			<Flex sx={baseStyles}>
				<HStack spacing="0.5rem">
					<Stack direction={['column', 'row']} spacing={[0, '1rem']}>
						{event?.id ? (
							<Heading as="h3" size="md" fontSize={['1rem', '1.5rem']}>
								<strong>
									{event?.status?.toUpperCase()}
									{status}
								</strong>
							</Heading>
						) : (
							<Heading as="h3" size="md">
								<strong>{`${role?.toUpperCase()} ACCESS`}</strong>
							</Heading>
						)}
						<HStack>
							{event?.status == 'published' &&
								(!publishedMedia || publishedMedia.inGlacierStorage || publishedMedia.isRestoring) && (
									<Heading as="h4" size="sm">
										<strong>Thawing Final Video</strong>
									</Heading>
								)}
							{isImpersonated && (
								<>
									<Text fontSize={['0.875rem', '1rem']}>
										Impersonating: <strong>{email?.toLowerCase()}</strong>{' '}
									</Text>
									<Button
										h="24px"
										size="xs"
										variant="impersonateButton"
										onClick={() => handleStopImpersonating()}>
										Stop
									</Button>
								</>
							)}

							{event?.id && !isImpersonated && (
								<>
									<Button
										size="xs"
										h="24px"
										variant="impersonateButton"
										onClick={() => handleImpersonateUser(event.creatorId)}>
										Impersonate Creator
									</Button>
								</>
							)}
						</HStack>
					</Stack>
				</HStack>

				<Flex align="center">
					{event?.id && (
						<Box right="3" position="absolute">
							<Switch
								colorScheme="whiteAlpha"
								size={isMobile ? 'md' : 'lg'}
								defaultChecked={showToolbox}
								onChange={setToolboxVisibility}
							/>
						</Box>
					)}
				</Flex>
			</Flex>
			{showToolbox && <SiteToolbox />}
		</Box>
	)
}

SiteStatus.defaultProps = {
	toolboxStyles: {},
}

SiteStatus.propTypes = {
	/** Styles adjustments passed down from parent */
	toolboxStyles: PropTypes.object.isRequired,
	/** State passed from parent / if the  */
	showToolbox: PropTypes.bool.isRequired,
	/** Handler to update the visibility of the Toolbox */
	setToolboxVisibility: PropTypes.func.isRequired,
}

export default SiteStatus
