/* **************************************
 *  LIBRARIES
 * ************************************ */

import React from 'react'
import { Box, keyframes } from '@chakra-ui/react'

export const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
`

/* **************************************
 *  STYLES
 * ************************************ */
const wrapperStyles = {
	maxW: '100%',
	maxH: '100%',
	pos: 'relative',
	top: '0',
	right: '0',
	bottom: '0',
	left: '0',
	margin: '1rem auto',
	display: 'flex',
	boxAlign: 'center',
	flexAlign: 'center',
	alignItems: 'center',
	boxPack: 'center',
	flexPack: 'center',
	justifyContent: 'center',
	boxOrient: 'vertical',
	boxDirection: 'normal',
	flexDirection: 'column',
}

const spanStyles = {
	pos: 'absolute',
	fontWeight: '800',
	// fontSize: '26px',
	marginLeft: 'auto',
	marginRight: 'auto',
	width: '70px',
	paddingLeft: '10px',
	textAlign: 'center',
	_after: {
		content: '"%"',
		fontSize: '12px',
		verticalAlign: 'top',
		lineHeight: '170%',
		marginLeft: '1px',
	},
}

const svgStyles = {
	'transform': 'rotate(-90deg)',
	'animation': `${spin} 0.75s infinite linear`,
	'& .progress_meter': {
		fill: 'none',
		stroke: '#e6e6e6',
	},
	'& .progress_value': {
		fill: 'none',
		stroke: '#04D898',
		strokeLinecap: 'round',
		transition: 'all 300ms ease-in-out 0s',
	},
}

const Progress = ({ value, size }) => {
	const _size = size || 64
	const strokeWidth = 8

	const widthHeight = _size * 2
	const radius = _size - strokeWidth / 2
	const cercumference = 2 * Math.PI * radius

	const progress = value / (100 + strokeWidth / 4)
	const dashoffset = cercumference - cercumference * progress

	const svgPercentage = {
		strokeDashoffset: dashoffset,
		strokeDasharray: cercumference,
	}

	return (
		<Box sx={wrapperStyles}>
			<Box sx={spanStyles}>{value}</Box>
			<Box sx={svgStyles} width={widthHeight} height={widthHeight} viewBox={`0 0 ${widthHeight} ${widthHeight}`}>
				<circle className="progress_meter" cx={_size} cy={_size} r={radius} strokeWidth={strokeWidth} />
				<circle
					className="progress_value"
					cx={_size}
					cy={_size}
					r={radius}
					strokeWidth={strokeWidth}
					style={svgPercentage}
				/>
			</Box>
		</Box>
	)
}

export { Progress }

export default Progress
