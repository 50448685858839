/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA } from 'redux-api-middleware'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../constants'
import { retrieveURL } from '@vidday/utils'
import { addFlashMessage } from '../../app/behaviors/flash-messages'

/* ******************************
 * POST PUBLISHED MEDIA OBJECT TO AMAZON S3
 ***************************** */

export function postPublishedMedia(data) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.UPLOAD_PUBLISHED_MEDIA_REQUEST,
					{
						type: types.UPLOAD_PUBLISHED_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								if (res.status === 201) {
									dispatch(addFlashMessage('info', json.message))
								} else {
									//console.log("post event media success ", json);
									// send flash for sucess
									dispatch(addFlashMessage('success', json.message))
								}
								return json
							})
						},
					},
					{
						type: types.UPLOAD_PUBLISHED_MEDIA_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								// send flash for error
								dispatch(addFlashMessage('error', json.error))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/publish/media',
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(data),
			},
		})
	}
}

/* ******************************
 * POST PUBLISHES A RENDER JOB FOR ITS RELATED EVENT
 ***************************** */
export function publishRenderJob(id) {
	return (dispatch) => {
		dispatch({
			[RSAA]: {
				types: [
					types.PUBLISH_RENDER_JOB_REQUEST,
					{
						type: types.PUBLISH_RENDER_JOB_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.PUBLISH_RENDER_JOB_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								// send flash for error
								dispatch(addFlashMessage('error', json.error))
								return json
							})
						},
					},
				],
				endpoint: `${retrieveURL()}/api/v2/render-jobs/${id}/publish`,
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				// body: undefined, // no body needed for this POST
			},
		})
	}
}

/* ******************************
 * REPORT UPLOAD PROGRESS
 ***************************** */
export function uploadProgress(percentage) {
	return {
		type: types.UPLOAD_PUBLISHED_MEDIA_PROGRESS,
		payload: { percentage: percentage },
	}
}

/* ******************************
 * REPORT Zipped PROGRESS
 ***************************** */
export function zipProgress(percentage) {
	return {
		type: types.COMPRESS_VIDDAY_PACKAGE_PROGRESS,
		payload: { percentage: percentage },
	}
}

/* ******************************
 * COMPRESS MEDIA PACKAGE FOR DOWNLOAD
 ***************************** */
export function compressViddayPackage(uuid) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.COMPRESS_VIDDAY_PACKAGE_REQUEST,
					{
						type: types.COMPRESS_VIDDAY_PACKAGE_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.COMPRESS_VIDDAY_PACKAGE_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/admin/viddaypackage/' + uuid,
				method: 'GET',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
			},
		})
	}
}

/* ******************************
 * Fix Duplicate Published Media - Remove duplicate published media leading to 404
 ***************************** */
export function fixDuplicatePublishedMedia(data) {
	return (dispatch, state) => {
		dispatch({
			[RSAA]: {
				types: [
					types.FIX_DUPLICATE_PUBLISHED_MEDIA_REQUEST,
					{
						type: types.FIX_DUPLICATE_PUBLISHED_MEDIA_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					{
						type: types.FIX_DUPLICATE_PUBLISHED_MEDIA_FAILURE,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('error', json.message))
								return json
							})
						},
					},
				],
				endpoint: retrieveURL() + '/api/events/fixduplicatepublishedmedia',
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ uuid: data.uuid }), // filter out only uuid
			},
		})
	}
}
