import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

const FinalViddayLink = ({ data }) => {
	const publishedDate = data.publishedDate ? 'Published on: ' + dayjs(data.publishedDate).format('llll') : ''

	return (
		<Box>
			<Text fontWeight="bold">Final VidDay Link</Text>
			{publishedDate && <Text>{publishedDate}</Text>}
			{!publishedDate && <Text>No date yet, as no published Media available yet.</Text>}
		</Box>
	)
}

export default FinalViddayLink
