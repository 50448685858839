import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { automationTemplates } from '@vidday/data'
import Progress from '../../../v2/ui/molecules/Progress'
import { publishRenderJob } from '../../../api/admin/media'
import { deleteRenderJob } from '../../../api/admin/events'
import { useDispatch } from 'react-redux'
import { useSocketContext } from '../../../v2/contexts/SocketProvider'

import loadable from '@loadable/component'
const RenderJobTasks = loadable(() => import(/* webpackPrefetch: true */ '../render-job-tasks'), {
	ssr: false,
})

dayjs.extend(localizedFormat)

import {
	Link,
	Badge,
	Box,
	HStack,
	Text,
	Button,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from '@chakra-ui/react'
import { jobTasks } from '../../../v2/ui/molecules/render-job-progress/job-tasks'

const EventRenderJob = ({ job }) => {
	// const job = {
	// 	id: '44918e06-70b2-42db-8d8e-40f669070f26',
	// 	eventId: '960ac22e-fe07-4605-9536-b3910ed6a250',
	// 	createdBy: '6ae6f5e5-f48c-403f-b11c-5e2e4e674a0e',
	// 	jobUid: '10044',
	// 	jobState: 'render:setup',
	// 	outputKey: 'a-6tnk72/published/83063102dfd739332e4158455b93ef89.mp4',
	// 	thumbnailKey: 'a-6tnk72/published83063102dfd739332e4158455b93ef89_thumbnail.jpg',
	// 	duration: 14.072,
	// 	themeId: 'lots-of-love',
	// 	producer: 'ffmpeg',
	// 	retries: 0,
	// 	preview: false,
	// 	archive: false,
	// 	dispatched: true,
	// 	updatedAt: '2024-04-19T16:46:59.772Z',
	// 	createdAt: '2024-04-19T15:18:55.073Z',
	// 	signedOutputUrl:
	// 		'https://media-dev.vidday.com/a-6tnk72/published/83063102dfd739332e4158455b93ef89.mp4?t=1713545219772&Expires=1714150019883&Key-Pair-Id=K36TXZ29TSZC59&Signature=Hgun-CTb6UZTd18w48FnKQykeuY9VtrWh9vBFlm5iBEKGQFoOUEMw~dI0~uF3~tQ02mUQ94hcTLKt0KH8aI-~FUEdkX3t2JwVADEJMbsNLjmjs4EWFAh9qTlVEWiB0j2dUps55ZZ3tJ6GQhZdD5pWkNk4MRX5KewOd~8oP4aoTXo-~o1fonfeZnvfamwiXhQnNb1etGNxn-H7w6l9Ca9HP-G9UdD45iqIuftdB5wq103mWPEQy-HEh9S7MtWkzoLcuGjonxYly8WwdeNr0joIPuxDAryhKPP-BQ2iSmSO0JJt6ZxavuqRuRFMVWCRC2ScfFBYoNOpw4JC3mKAD5DCQ__',
	// 	signedThumbnailUrl:
	// 		'https://media-dev.vidday.com/a-6tnk72/published83063102dfd739332e4158455b93ef89_thumbnail.jpg?t=1713545219772&Expires=1714150019889&Key-Pair-Id=K36TXZ29TSZC59&Signature=yStSMnz3~6~fkkOQ7gtv5~ruOpHl4BNEcfBY2LVNHm~SJXEin6XyxYsRnbm-t1E57cScqVKeB~eI08FcFB1qZ2P~p3eTpSnxxfCCrMM8VcRKJ9RvR1pz9CUsGsIdwrIfomT8phhK7INkItQS9PQWjzbDGTka9XMfYRboE9C3aRku8lqhuNS2Kre-vvVcjIWg0uDHYcAZZc7lygea21k1jUMtyBNc187FzTahimgm92rkMZcyGeubrAQg8YBLo4LF6qDOgT-wA-sIjH4PZMIoqNtceRAwXPBiykPAthfjxaS-LdOsSc1cp9ak~bacTNJD-SRRTQHwFksUye48k~dDUQ__',
	// }

	const [renderProgress, setRenderProgress] = useState(null)
	const [renderJobState, setRenderJobState] = useState(null)
	const [jobParts, setJobParts] = useState(null)
	const [completedTasks, setCompletedTasks] = useState([])
	const [signedOutputUrl, setSignedOutputUrl] = useState(null)

	const dispatch = useDispatch()

	// grab staff socket namespace from SocketProvider
	const {
		staff: { socket },
	} = useSocketContext()

	const handleReview = () => {
		let mediaUrl = job.signedOutputUrl
		let win = window.open(mediaUrl, '_blank')
		win.focus()
	}

	const handlePublish = () => {
		console.log(`Publishing Render Job ${job.id}...`)
		dispatch(publishRenderJob(job.id))
	}

	const handleDelete = () => {
		dispatch(deleteRenderJob(job.id))
	}

	const getCompletedTasks = (currentState) => {
		let completedTasks = ['dispatching']
		let tasks = jobTasks
		let taskIndex = tasks.indexOf(currentState)
		if (currentState !== null && taskIndex > -1) {
			completedTasks = tasks.slice(0, taskIndex + 1)
		}
		return completedTasks
	}

	useEffect(() => {
		// get completed task lists
		let completedTasks = getCompletedTasks(job.jobState)

		setRenderJobState(job.jobState)
		setRenderProgress(job.renderProgress)
		setSignedOutputUrl(job.signedOutputUrl)
		setCompletedTasks(completedTasks)
		setJobParts({})

		// this one is for when existing render jobs are updated
		socket.on('renderJobState', (socketPayload) => {
			// filter by jobUid
			if (socketPayload.data.id == job.id) {
				// Get computed values
				let completedTasks = getCompletedTasks(socketPayload.data.jobState)
				if (socketPayload.data.jobState == 'error') {
					completedTasks = ['error']
				}

				let jobPartsTemp = jobParts
				if (socketPayload.data.jobPart) {
					jobPartsTemp[socketPayload.data.jobPart] = socketPayload.data.progress
				}
				setRenderJobState(socketPayload.data.jobState)
				setRenderProgress(socketPayload.data.progress)
				setSignedOutputUrl(socketPayload.data.signedOutputUrl)

				setCompletedTasks(completedTasks)
				setJobParts(jobPartsTemp)
			}
		})

		// deregister `renderJobState` listener
		return () => {
			socket.off('renderJobState')
		}
	}, [])

	const theme = automationTemplates
		? automationTemplates.filter((theme) => theme.id == job.themeId)[0]
		: automationTemplates.filter((theme) => theme.default)[0]

	const jobCreatedDate = job.createdAt ? dayjs(job.createdAt).format('llll') : ''
	return (
		<>
			<Box
				key={job.id}
				w="full"
				pos="relative"
				boxShadow="base"
				bg="blackAlpha.50"
				borderRadius="base"
				borderLeft={'6px'}
				borderColor={!job.preview ? 'green.500' : 'transparent'}
				p="1rem">
				{job.preview ? <Text fontWeight="bold">PREVIEW</Text> : <Text fontWeight="bold">FINAL</Text>}

				<HStack alignItems="center">
					<Text color="gray.500" w="10%">
						Job UID:
					</Text>
					<Text color="gray.700">{job.jobUid}</Text>
				</HStack>
				<HStack alignItems="center">
					<Text color="gray.500" w="10%">
						Created At:
					</Text>
					<Text color="gray.700">{jobCreatedDate}</Text>
				</HStack>
				<HStack alignItems="center">
					<Text color="gray.500" w="10%">
						Theme:
					</Text>
					<Text color="gray.700">{theme && theme.label}</Text>
				</HStack>
				<HStack alignItems="center">
					<Text color="gray.500" w="10%">
						Job State:
					</Text>
					<Badge
						colorScheme={
							!renderJobState || renderJobState == 'dispatching'
								? 'blue'
								: renderJobState == 'error'
								? 'pink'
								: 'green'
						}>
						{renderJobState ? renderJobState : 'Dispatching'}
					</Badge>
				</HStack>

				{signedOutputUrl && (
					<HStack alignItems="center">
						<Text color="gray.500" w="10%">
							Output location:
						</Text>
						<Link
							color="cyan.700"
							fontWeight="600"
							as="a"
							target="_blank"
							href={signedOutputUrl}
							rel="noreferrer">
							{signedOutputUrl.split('?')[0]}
						</Link>
					</HStack>
				)}

				{renderJobState && renderJobState != 'finished' && renderJobState != 'error' && (
					<Box w="full">
						<RenderJobTasks tasks={jobTasks} completedTasks={completedTasks} currentTask={renderJobState} />
					</Box>
				)}

				{renderProgress != null &&
					(renderJobState == 'render:dorender' || renderJobState == 'render:postrender') && (
						<Box w="full">
							<Text>Rendering progress</Text>
							<Box sx={{ width: 'full', m: 'auto' }}>
								{Object.values(jobParts).map((value, index) => {
									return (
										<span key={index} style={{ display: 'inline-block', margin: '0 16px' }}>
											<Progress value={value} size={50} />
											<small>
												Part {index + 1}/{Object.values(jobParts).length}
											</small>
										</span>
									)
								})}
							</Box>
						</Box>
					)}
				<br />
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton>
							<Box flex="1" textAlign="left">
								<Text color="gray.500">Raw Data:</Text>
							</Box>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel pb={4}>
							<pre
								style={{
									border: '1px solid #eee',
									whiteSpace: 'pre-wrap',
									wordWrap: 'break-word',
									textAlign: 'left',
									userSelect: 'text',
								}}
								dangerouslySetInnerHTML={{ __html: JSON.stringify(job, undefined, 4) }}
							/>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>

				<Box pos="absolute" top="1rem" right="1rem">
					<HStack>
						<Button
							size="xs"
							onClick={() => handleReview()}
							disabled={renderJobState != 'finished' && renderJobState != 'render:cleanup'}>
							Review
						</Button>

						{!job.preview && (
							<Button size="xs" onClick={() => handlePublish()}>
								Publish
							</Button>
						)}

						<Button size="xs" variant="outline" colorScheme="red" onClick={() => handleDelete()}>
							Delete
						</Button>
					</HStack>
				</Box>
			</Box>
		</>
	)
}

export default EventRenderJob
