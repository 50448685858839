import React, { useRef } from 'react'
import { eventStatusTypes } from '@vidday/data'
import { updateEvent } from '../../../api/admin/events'
import { addFlashMessage } from '../../../api/app/behaviors/flash-messages'
import { useDispatch } from 'react-redux'
import { Text, Button, Select } from '@chakra-ui/react'

const EventForm = ({ data, publishedMedia }) => {
	const dispatch = useDispatch()
	const statusRef = useRef(null)

	const items = eventStatusTypes
		? eventStatusTypes.map((item, i) => (
				<option value={item['type']} key={i}>
					{item['label']}
				</option>
		  ))
		: null
	const handleSubmit = (e) => {
		e.preventDefault()
		const val = statusRef.current.value

		let postData = {
			id: data.id,
			status: val,
		}

		if (val == 'published' && (!publishedMedia || publishedMedia.length === 0)) {
			dispatch(addFlashMessage('info', 'Event does not contain any published media'))
		} else {
			dispatch(updateEvent(postData))
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Text>Status:</Text>
			<Select name="status" ref={statusRef} defaultValue={data.status} mb="1rem">
				<option value="">Select</option>
				{items}
			</Select>
			<Button size="xs" type="submit" minW="100px">
				Save
			</Button>
		</form>
	)
}

export default EventForm
